import Swal from "sweetalert2";
import {Permission} from "~/types/interfaces/entitites/RoleInterface";

export function useUtil() {

    const showLoading = async () => {
        Swal.fire({
            title: 'Cargando',
            text: 'Estamos cargando los datos, por favor, espera.',
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
            allowOutsideClick: false,
            didOpen: () => {
                Swal.showLoading();
            },
        });
    };


    const closeLoading = async () => {
        Swal.hideLoading();
        Swal.close();
    };
    const wip = async () => {
        Swal.fire({
            title: 'WIP',
            text: 'Esta funcionalidad estará disponible cuando la versión BETA esté finalizada.',
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultSaveSuccess = () => {
        Swal.fire({
            title: 'Datos guardados',
            text: 'Se han actualizado los datos correctamente',
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultCopySuccess = () => {
        Swal.fire({
            title: 'Copiado con éxito',
            text: 'Se ha copiado al portapapeles',
            icon: "info",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultRemoveSuccess = () => {
        Swal.fire({
            title: 'Datos eliminados',
            text: 'Se han borrado los datos correctamente',
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultDownloadSuccess = () => {
        Swal.fire({
            title: 'Archivo descargado',
            text: 'Se ha descargado el archivo correctamente',
            icon: "success",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-primary",
            },
        });
    };

    const defaultError = (title: string, message: string) => {
        Swal.fire({
            title: title,
            text: message,
            icon: "error",
            buttonsStyling: false,
            confirmButtonText: "Entendido",
            heightAuto: false,
            customClass: {
                confirmButton: "btn fw-semibold btn-light-danger",
            },
        });
    };

    const hasTypePermission = (permissions: Permission[], type: string) => {
        return permissions.find((permission, index) => {
            return permission.type === type;
        }) !== undefined;
    };

    const hasPermission = (permissions: Permission[], type: string, grant: string) => {
        return permissions.find((permission, index) => {
            return permission.type === type && permission.grant === grant;
        }) !== undefined;
    };

    const dmYFormat = (date: Date) => {
        if (date) {
            const day = date.getDate();
            const month = date.getMonth() + 1;
            const year = date.getFullYear();

            return `${day}/${month}/${year}`;
        }

        return '';
    }

    const isPastDate = (date: Date|string) => {
        let dateObj: Date;

        if (typeof date === 'string') {
            dateObj = createDateFromdmYFormat(date);
        } else {
            dateObj = date;
        }

        const today = new Date();
        today.setHours(0, 0, 0, 0);

        return dateObj <= today;
    }

    const createDateFromdmYFormat = (dateString: string) => {
        const [datePart] = dateString.split(' ');
        const [day, month, year] = datePart.split('/').map(Number);
        return new Date(year, month - 1, day);
    }

    const randomString = (length: number) => {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < length) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result;
    }

    const searchString = (search: string, word: string) => {
        return word.toLowerCase().includes(search.toLowerCase());
    }

    const searchInArrayObject = (search: string, array: [], objectKey: string, multiSelectFormat: boolean = true) => {

        const newArray = [];

        array.find((object) => {
            if (searchString(search, object[objectKey])) {
                if (multiSelectFormat) {
                    newArray.push({value: object.hash, label: object.name, name: object.name });
                } else {
                    newArray.push(object);
                }
            }
        })

        return newArray;
    }

    const truncateString = (string: string, length: number) => {
        if (string.length > length) {
            return string.substring(0, length) + "...";
        }
        return string;
    };

    const calculateElapsedMinutes = (startTime: Date, currentTime: Date) => {
        const elapsedTime = (currentTime - startTime) / 1000; // Diferencia en segundos

         // Convertir a minutos y redondear hacia abajo
        return Math.floor(elapsedTime / 60);
    };

    return {
        showLoading,
        closeLoading,
        wip,
        defaultSaveSuccess,
        defaultCopySuccess,
        defaultRemoveSuccess,
        defaultDownloadSuccess,
        defaultError,
        hasTypePermission,
        hasPermission,
        dmYFormat,
        randomString,
        searchString,
        searchInArrayObject,
        truncateString,
        calculateElapsedMinutes,
        isPastDate,
        createDateFromdmYFormat
    };
}
